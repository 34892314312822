
    @font-face {
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: normal;
    src: local('Libre Baskerville'), url('LibreBaskerville-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Libre Baskerville Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Libre Baskerville Italic'), url('LibreBaskerville-Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Libre Baskerville Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Libre Baskerville Bold'), url('LibreBaskerville-Bold.woff') format('woff');
    }

    @font-face {
    font-family: 'poppinsbold';
    src: url('poppins-bold-webfont.eot');
    src: url('poppins-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('poppins-bold-webfont.woff2') format('woff2'),
         url('poppins-bold-webfont.woff') format('woff'),
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinslight';
    src: url('poppins-light-webfont.eot');
    src: url('poppins-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('poppins-light-webfont.woff2') format('woff2'),
         url('poppins-light-webfont.woff') format('woff'),
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsmedium';
    src: url('poppins-medium-webfont.eot');
    src: url('poppins-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('poppins-medium-webfont.woff2') format('woff2'),
         url('poppins-medium-webfont.woff') format('woff'),
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsregular';
    src: url('poppins-regular-webfont.eot');
    src: url('poppins-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('poppins-regular-webfont.woff2') format('woff2'),
         url('poppins-regular-webfont.woff') format('woff'),
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinssemibold';
    src: url('poppins-semibold-webfont.eot');
    src: url('poppins-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('poppins-semibold-webfont.woff2') format('woff2'),
         url('poppins-semibold-webfont.woff') format('woff'),
    font-weight: normal;
    font-style: normal;

}